import React from 'react'



const Newsletter = ({ title, url, className }) => {
  return (
    <div className={`Newsletter md:w-2/3 sm:w-full rounded overflow-hidden shadow ${className}`}>
      <form action="https://upscri.be/ft93mj" method="post" className="flex overflow-hidden">
        <input type="email" name="email" placeholder="Email" className="flex-grow px-4 py-2 bg-inherit" required />
        <button className="bg-indigo-500 hover:bg-indigo-400 transition duration-150 ease-in-out font-medium text-sm text-white float-right px-4 py-2 flex-none" type="submit">Submit</button>
      </form>
    </div>
  )
}

export default Newsletter

