import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import Newsletter from "../components/newsletter"
import { TwitterIcon, LinkedinIcon } from "react-share";
import Link from 'gatsby-link'

export const IndexPage = ({ data: { site: { siteMetadata: { title, aboutMe, siteUrl } }, allMarkdownRemark, markdownRemark: { frontmatter: { title: post_title, summary: post_summary, path: post_path } } } }) =>
  <Layout>
    <Helmet
      title={title}
      meta={[
        { property: 'og:title', content: `${title} | Software Developer` },
        { property: 'og:url', content: `${siteUrl}` },
      ]}
    >

    </Helmet>
    <div className="mt-2 rounded-lg shadow p-4 -m-4 md:p-6 md:-m-6 bg-white">
      {/* <h1 className="text-3xl lg:w-3/4 font-bold leading-none mb-4">Conner "on the" Fritz</h1> */}

      <div className="flex mb-4">
        <h3 className="text-lg font-normal text-gray-700 leading-tight ">
          <div>Hey, I'm Conner, and I'm a Software Developer turned Engineering Leader based in Toronto.</div>
          <div className="mt-4">I lead the engineering team at Universe, where we're building the most supportive culture, the most collaborative team, and the best ticketing software on the planet. Sometimes I write code too.</div>
        </h3>
      </div>

      <h2 className="text-xl font-medium text-black-500 leading-none mt-4">I'm here to build empathetic, supportive tech organizations and chew bubblegum and I'm all out of bubblegum.</h2>
    </div>

    <div className="mt-16">
      <h3 className="text-lg font-semibold">Get attitude</h3>
      <div className="text-md">
        Follow me on <a href="https://twitter.com/connerfritz" className="hover:underline text-indigo-500" target="_blank" rel="noreferrer">Twitter</a> or add me on <a href="https://linkedin.com/in/connerfritz" className="hover:underline text-indigo-500" target="_blank" rel="noreferrer">LinkedIn</a> to get the latest bad attitude from yours truly.
      </div>
      <div className="mt-4 text-md">
        Or get attitidue directly by contacting me at <a className="text-indigo-500 font-medium" href="mailto:me@connerfritz.com?Subject=Hello%20Conner">me@connerfritz.com</a>. Replies not guaranteed (I have an "Inbox One Million" policy).
      </div>
    </div>

    {/*
    <div className="mt-8">
      <h3 className="text-lg font-semibold">Newsletter</h3>
      <div className="text-md">Join my mailing list to receive news and updates right to your inbox.</div>
      <Newsletter className="mt-4 bg-white" />
    </div>
    */}

    {/* <div className="my-8">
      <h3 className="text-lg font-semibold">Bug me</h3>
      <div className="text-md">If you really need to, drop me an email at <a className="text-indigo-500 font-medium" href="mailto:me@connerfritz.com?Subject=Hello%20Conner">me@connerfritz.com</a>. Replies not guaranteed (I have an "Inbox One Million" policy).</div>
    </div> */}

    <div className="my-10">
      <h3 className="text-lg font-semibold">Featured post</h3>
      <div className="text-md">
        I wrote a tutorial about my method for adding metatags to a Phoenix application.
      </div>
      <Link to={post_path} className="bg-white rounded block mt-2 p-4 -m-4 md:-mx-6 md:px-6 shadow py-4">
        <h4 className="text-lg font-medium text-indigo-500">{post_title}</h4>
        <div className="text-md">{post_summary}</div>

        <Link to={post_path} className="inline-block mt-2 text-indigo-500">Read it now</Link>
      </Link>
    </div>

    <div className="my-10">
      <h3 className="text-lg font-semibold">Recent posts</h3>
      {allMarkdownRemark.edges.slice(1).map(
        ({ node: { frontmatter: { path, title, summary, date } } }) => (
          <Link to={path} className="block mb-4">
            <h4 className="text-lg font-medium text-indigo-500 leading-tight">{title}</h4>
            <div className="text-md">{summary}</div>
            <div className="text-sm italic text-gray-700 font-thin">Published {date}</div>
          </Link>
        )
      )}
    </div>
  </Layout>


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        aboutMe
        siteUrl
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            path
            summary
            title
            date(formatString: "MMMM D, YYYY")
          }
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: "/blog/adding-title-and-metadata-to-a-phoenix-site/" } }) {
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        title
        path
        summary
      }
      fields {
        slug
      }
    }
  }
`;

export default IndexPage
